import React, { useRef, useEffect, useState } from 'react';
import {
  PieChart as PieChatCon,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart,
  Area,
} from "recharts";
import { useGetPieChartQuery } from "./pieChartLotsApi";
import { Card, Button } from "antd";
import html2canvas from 'html2canvas';
import '../../index.css'; // Import the custom CSS

interface PieChartProps {
  field: string;
  chartType: string;
  filter: any;
  lot:number;
}
interface LegendPayload {
  id: string;
  type: "square";
  value: string;
  color: string;
}
export const PieChartLots = ({ field, chartType, filter,lot }: PieChartProps) => {
  const { data } = useGetPieChartQuery({
    field,
    filter,
    lot,
  }) as { data: { chartData: { type: string; value: number }[] } };

  function getCurrentDateTime() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
  
    return `${day}${month}${year}${hours}${minutes}`;
  }

  const handleDownload = () => {
    if (!data || !data.chartData || data.chartData.length === 0) {
      alert("No data available to download.");
      return;
    }
    
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const timeNow = getCurrentDateTime();
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = `${chartType}-chart${timeNow}.png`;
        link.click();
      });
    }
  };

  const generateColors = (count: number): string[] => {
    const baseColors = ["#33FF57","#3357FF","#FF33A8","#33FFF3","#FFBD33","#8D33FF","#FF33EC","#33FF96","#FF8633","#339FFF",
      "#FF33C4","#33FFDA","#FF333F","#333FFF","#A833FF","#FF5733","#FF8333","#33FF83","#FF3333","#33FF33"];
    const colors = [];
    for (let i = 0; i < count; i++) {
      colors.push(baseColors[i % baseColors.length]);
    }
    return colors;
  };

  const [chartDatas, setChartDatas] = useState<{ type: string; value: number }[]>([]);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  // const [legendColors, setLegendColors] = useState<{ [key: string]: string }>({});
  // const [activeLegends, setActiveLegends] = useState<Set<string>>(new Set());
  const chartRef = useRef(null);
  
  // useEffect(() => {
  //   if (data && data.chartData) {
  //     setChartDatas(data.chartData);
  //     setColors(generateColors(data.chartData.length));
  //   }
  // }, []);
  useEffect(() => {
    if (data && data.chartData) {
      setChartDatas(data.chartData);
      setColors(generateColors(data.chartData.length));
      setActiveIndices([]);
    }
  }, [data,field,chartType,filter]);
  
  // useEffect(() => {
  //   if (chartData.length > 0) {
  //     const colors = generateColors(chartData.length);
  //     const colorsMap = chartData.reduce((acc, item, index) => {
  //       acc[item.type || 'Unknown'] = colors[index];
  //       return acc;
  //     }, {} as { [key: string]: string });
  //     setLegendColors(colorsMap);
  //   }
  // }, [chartData]);

  const onLegendClick = (e: { value: string }) => {
    const index = chartDatas.findIndex((d) => d.type === e.value);
    setActiveIndices((prev) =>
      prev.includes(index)
        ? prev.filter((i) => i !== index) // Remove index if already active
        : [...prev, index] // Add index if not active
    );
  };

  const visibleData = chartDatas.filter((_, i) => !activeIndices.includes(i));
  const legendColors: Record<string, string> = colors.reduce((acc, color, index) => {
    acc[chartDatas[index].type] = color;
    return acc;
  }, {} as Record<string, string>);

  

  const renderChart = () => {
    switch (chartType) {
      case 'pie':
        return (
          <ResponsiveContainer width={800} height={400}>
           <PieChatCon>
            <Pie
              data={visibleData}
              dataKey="value"
              nameKey="type"
              cx="50%"
              cy="50%"
              outerRadius={150}
              label={({ name }) => name}
              isAnimationActive={false}
            >
              {visibleData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={legendColors[entry.type]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend
              payload={chartDatas.map((item, index) => ({
                id: item.type,
                type: "square",
                value: item.type,
                color: activeIndices.includes(index) ? "#D3D3D3" : colors[index],
              }))}
              onClick={onLegendClick}
              // formatter={(value, entry, index) => {
              //   const itemValue = chartDatas[index].value;  // control the legends
              //   return (
              //     <span style={{ fontSize: '16px' }}>
              //       {value} ({itemValue})
              //     </span>
              //   );
              // }}
            />
          </PieChatCon>
          </ResponsiveContainer>
        );
      case 'bar':
        return (
          <ResponsiveContainer width="100%" height={400}>
           <BarChart data={visibleData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="type" />
            <YAxis />
            <Tooltip />
            <Legend
              payload={chartDatas.map((item, index) => ({
                id: item.type,
                type: "square",
                value: item.type,
                color: activeIndices.includes(index) ? "#D3D3D3" : colors[index],
              }))}
              onClick={onLegendClick}
            />
            <Bar dataKey="value">
              {visibleData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={legendColors[entry.type]} />
              ))}
            </Bar>
          </BarChart>
          </ResponsiveContainer>
        );
      case 'area':
        return (
          <ResponsiveContainer width="100%" height={400}>
             <AreaChart data={visibleData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="type" />
            <YAxis />
            <Tooltip />
            <Legend
              payload={chartDatas.map((item, index) => ({
                id: item.type,
                type: "square",
                value: item.type,
                color: activeIndices.includes(index) ? "#D3D3D3" : colors[index],
              }))}
              onClick={onLegendClick}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke={colors[0]}
              fill={colors[0]}
            />
          </AreaChart>
          </ResponsiveContainer>
        );
      default:
        return <p>Invalid chart type.</p>;
    }
  };
  console.log("the pie chart data",data);
  
  

  return (
    <Card extra={<Button onClick={handleDownload}>Download Chart</Button>}>
      <div ref={chartRef} style={{ height: '100%', textAlign: 'center' }}>
        <h1 style={{ fontSize: "1.7rem" }}>Statistic by {field}</h1>
        {renderChart()}
        {visibleData.length > 0 && (
        <div
          style={{
            textAlign: 'left',
            marginTop: '20px',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ flex: '1 0 20%', margin: '0 10px' }}>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              {visibleData.map((item, idx) => (
                <li key={idx} style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      width: '220px',
                      marginRight: '8px',
                      backgroundColor: legendColors[item.type] || '#FFFFFF',
                      padding: '4px 8px',
                      color: 'white',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'inline-block',
                    }}
                  >
                    {item.type || 'Undefined'}
                  </span>
                  <span style={{ marginLeft: '10px', fontFamily: 'monospace' }}>
                    {item.value}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      </div>
    </Card>
  );
};

export default PieChartLots;
